import React, { useState, useCallback } from "react";
import {
  useGetMeQuery,
  useGetMilanEventByCityQuery,
  useCreateMilanRegistrationMutation,
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Modal,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import Header from "../../../combonents/sa/header/header";
import Loader from "../../../combonents/Loader/loader";
interface MilanEvent {
  id: string;
  name: string;
  price: number;
  maxParticipants: number;
  description: string[];
  venue: string;
  date: string;
}

const Events = () => {
  const { city = "chennai" } = useParams<{ city?: string }>();
  const { data, loading, error } = useGetMilanEventByCityQuery({
    variables: { city },
  });
  const { data: userData } = useGetMeQuery();

  const [createMilanRegistration] = useCreateMilanRegistrationMutation();

  // State management
  const [open, setOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState<
    string[] | null
  >(null);
  const [participants, setParticipants] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState<{
    id: string;
    price: number;
    maxParticipants: number;
  } | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<string>("");

  // Modal handlers
  const handleOpen = (description: string[]) => {
    setSelectedDescription(description);
    setOpen(true);
  };

  const handleOpenRegister = (
    eventId: string,
    price: number,
    maxpart: number
  ) => {
    setSelectedEvent({ id: eventId, price, maxParticipants: maxpart });
    setRegisterOpen(true);
    setErrorMsg(null);
    setPaymentError(null);
    setPaymentSuccess("");
  };

  const handleCloseRegister = () => {
    setRegisterOpen(false);
    setSelectedEvent(null);
    setParticipants(1);
    setPaymentError(null);
    setPaymentSuccess("");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDescription(null);
  };

  // Participants handler
  const handleParticipantsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);
    if (isNaN(value) || value < 0) {
      setParticipants(1);
      return;
    }
    setParticipants(value);
    if (selectedEvent && value > selectedEvent.maxParticipants) {
      setErrorMsg(
        `Maximum ${selectedEvent.maxParticipants} participants allowed`
      );
    } else {
      setErrorMsg(null);
    }
  };

  // Amount calculations
  const calculateBaseAmount = () => {
    return selectedEvent ? participants * selectedEvent.price : 0;
  };

  const calculateRazorpayFee = () => {
    return Math.round(calculateBaseAmount() * 0.02 * 100) / 100;
  };

  const calculateTotal = () => {
    return calculateBaseAmount() + calculateRazorpayFee();
  };

  // Payment handlers
  const handlePayment = useCallback(async () => {
    if(participants < 1) {
      throw new Error("participants cannot be less than 1");
    }
    if (!selectedEvent) return;
    if (!userData?.getMe.user_id) {
      throw new Error("user id  ???");
    }
    try {
      const { data: registrationData } = await createMilanRegistration({
        variables: {
          eventId: selectedEvent.id,
          userId: userData?.getMe.user_id || "",
          participants: participants,
        },
      });

      if (!registrationData?.createMilanRegistration) {
        throw new Error("Failed to create registration");
      }

      const totalAmount = Math.round(calculateTotal() * 100); // Convert to paise

      const razorpayKeyId =
        process.env.REACT_APP_RAZORPAY_KEY_ID || "rzp_live_VgVO9uzPsxViN5";
      if (!razorpayKeyId) {
        throw new Error("Razorpay Key ID is not set in environment variables");
      }

      const options: RazorpayOptions = {
        key: razorpayKeyId,
        amount: totalAmount,
        currency: "INR",
        name: "Saarang Milans",
        description: "Payment Transactions",
        order_id: registrationData.createMilanRegistration.razorpay_order_id,
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        notes: {
          type: "Saarang Milans 2025",
        },
        theme: {
          color: "#6f77fd",
        },
        prefill: {
          name: userData?.getMe.username,
          email: userData?.getMe.email_primary,
          contact: userData?.getMe.mobile_number_primary ?? "",
        },
      };

      if (typeof window.Razorpay === "undefined") {
        throw new Error("Razorpay script not loaded");
      }

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      if (error instanceof Error) {
        setPaymentError(`Payment initiation failed: ${error.message}`);
      } else {
        setPaymentError("Payment initiation failed. Please try again.");
      }
      console.error("Payment error:", error);
    }
  }, [selectedEvent, participants, createMilanRegistration, calculateTotal]);

  const handlePaymentSuccess = async (response: {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
  }) => {
    try {
      setPaymentSuccess("Payment successful! Your registration is confirmed.");
      handleCloseRegister();
    } catch (error) {
      console.error("Payment verification failed:", error);
      setPaymentError("Payment verification failed. Please contact support.");
    }
  };

  // Loading and error states
  if (loading)
    return (
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] scale-[5]">
        {" "}
        <Loader />
      </div>
    );
  if (error) return <div>Error loading events</div>;




  interface RegistrationModalProps {
    open: boolean;
    onClose: () => void;
    onRegister: () => void;
    participants: number;
    onParticipantsChange: (value: number) => void;
    errorMsg?: string;
    paymentError?: string;
    paymentSuccess?: string;
  }
  
  interface AmountCalculations {
    baseAmount: number;
    razorpayFee: number;
    totalAmount: number;
  }
  
  const calculateAmounts = (participants: number): AmountCalculations => {
    const baseAmount = participants * 100;
    const razorpayFee = baseAmount * 0.02;
    const totalAmount = baseAmount + razorpayFee;
  
    return {
      baseAmount,
      razorpayFee,
      totalAmount
    };
  };
  
  const RegistrationModal: React.FC<RegistrationModalProps> = ({
    open,
    onClose,
    onRegister,
    participants,
    onParticipantsChange,
    errorMsg,
    paymentError,
    paymentSuccess
  }) => {
    if (!open) return null;
  
    const { baseAmount, razorpayFee, totalAmount } = calculateAmounts(participants);
  
    const handleParticipantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      if (!isNaN(value) && value >= 0.001) {
        onParticipantsChange(value);
      }
    };
  
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <div 
          className="bg-gray-800 rounded-lg max-w-md w-full p-6 shadow-lg border border-gray-700"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-white">Register for Event</h2>
          </div>
  
          {/* Content */}
          <div className="space-y-4">
            <div>
              <label 
                htmlFor="participants"
                className="block text-sm font-medium text-gray-200 mb-1"
              >
                Number of Participants
              </label>
              <input
                id="participants"
                type="number"
                value={participants}
                onChange={handleParticipantsChange}
                min="0.001"
                step="0.001"
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white 
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                aria-invalid={!!errorMsg}
                aria-describedby={errorMsg ? "error-message" : undefined}
              />
            </div>
  
            {/* Error Messages */}
            {errorMsg && (
              <div 
                id="error-message"
                role="alert"
                className="text-red-500 text-sm"
              >
                {errorMsg}
              </div>
            )}
            {paymentError && (
              <div 
                role="alert"
                className="text-red-500 text-sm"
              >
                {paymentError}
              </div>
            )}
            {paymentSuccess && (
              <div 
                role="status"
                className="text-green-500 text-sm"
              >
                {paymentSuccess}
              </div>
            )}
  
            {/* Amount Breakdown */}
            <div className="bg-gray-700 p-4 rounded-md">
              <h3 className="text-white text-sm font-medium mb-3">Amount Breakdown</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-300">Base Amount:</span>
                  <span className="text-gray-300">{baseAmount.toFixed(2)} Rs</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-300">Razorpay Fee (2%):</span>
                  <span className="text-gray-300">{razorpayFee.toFixed(2)} Rs</span>
                </div>
                <div className="flex justify-between pt-2 border-t border-gray-600">
                  <span className="text-white font-medium">Total Amount:</span>
                  <span className="text-white font-medium">{totalAmount.toFixed(2)} Rs</span>
                </div>
              </div>
            </div>
          </div>
  
          {/* Footer */}
          <div className="mt-6 flex justify-between gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-md 
                       transition-colors duration-200 focus:outline-none focus:ring-2 
                       focus:ring-gray-500"
            >
              Close
            </button>
            <button
              type="button"
              onClick={onRegister}
              disabled={!!errorMsg}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md 
                       transition-colors duration-200 focus:outline-none focus:ring-2 
                       focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Register
            </button>
          </div>
        </div>
      </div>
    );
  };







  // Format date
  const eventDate = data?.getMilanEventByCity?.[0]?.date;
  const eventVenue = data?.getMilanEventByCity?.[0]?.venue;
  const formattedDate = eventDate
    ? new Date(eventDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
  return (
    <div className="bg-slate-900 min-h-[100vh] flex flex-col items-stretch space-y-24">
      <Header />
      {/* Header */}
      <div className="lg:px-64 rounded-md bg-slate-800 flex justify-center mx-4 items-center my-4 py-4 flex-col">
        <Typography variant="h1" className="text-[#6f77fd] font-bold">
          {city.charAt(0).toUpperCase() + city.slice(1)}
        </Typography>
        <div className="text-gray-300 whitespace-nowrap lg:text-xl text-lg">
          ({formattedDate}, {eventVenue})
        </div>
      </div>

      {/* Event Cards */}
      <div className="flex flex-wrap w-full gap-12 justify-center">
        {data?.getMilanEventByCity?.length ? (
          data?.getMilanEventByCity?.map((milanEvent) => (
            <div
              key={milanEvent.id}
              className="relative p-0.5 bg-no-repeat bg-[length:100%_100%] w-[20rem] text-white bg-slate-700 rounded-lg flex flex-col justify-between lg:h-[16rem] h-[12rem]"
            >
              <div className="md:text-3xl text-2xl text-neutral-200 font-semibold flex items-start justify-center py-4 w-full text-center lg:min-h-24 min-h-12">
                {milanEvent.name}
              </div>

              <Typography className="text-neutral-300 px-2 space-y-1 flex-shrink-0 text-center">
                <h1 className="text-lg">
                  Max Participants: {milanEvent.maxParticipants}
                </h1>
                {milanEvent.price && (
                  <h1 className="text-lg">Price: {milanEvent.price} Rs</h1>
                )}
              </Typography>

              <div className="flex flex-row px-2 justify-between items-center w-full mt-auto pb-4">
                <button
                  className="text-white font-Normal text-sm lg:text-lg tracking-wide bg-[rgb(111,119,253)] border-[1px] border-neutral-200 w-28 h-8 rounded-md"
                  onClick={() => handleOpen(milanEvent.description)}
                >
                  Description
                </button>
                {userData?.getMe?.user_id ? (
                  <button
                    className="text-neutral bg-[#6f77fd] font-bold text-sm lg:text-lg tracking-wide border-[1px] border-neutral-200 w-28 h-8 px-2 py-0 rounded-md"
                    onClick={() =>
                      handleOpenRegister(
                        milanEvent.id,
                        milanEvent.price,
                        milanEvent.maxParticipants
                      )
                    }
                  >
                    Register
                  </button>
                ) : (
                  <button
                    className="text-neutral bg-[#6f77fd] font-bold text-sm lg:text-lg tracking-wide border-[1px] border-neutral-200 w-28 h-8 px-2 py-0 rounded-md"
                    onClick={() => {
                      alert("Please login to register.");
                      window.location.href = "/auth";
                    }}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full py-20">
            <Typography variant="h2" className="text-gray-400">
              Coming Soon...
            </Typography>
          </div>
        )}
      </div>

      {/* Description Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="event-description-title"
        aria-describedby="event-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
            bgcolor: "#1f2937",
            border: "2px solid #000",
            boxShadow: "0 0 15px 5px rgba(255, 255, 255, 0.8)",
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography
            id="event-description-title"
            variant="h6"
            component="h2"
            className="text-white"
          >
            Event Description
          </Typography>
          {selectedDescription && (
            <List id="event-description" sx={{ mt: 2 }}>
           {selectedDescription.map((point, index) => (
                <ListItem key={index} className='text-slate-300'>
                    <ListItemText
                        primary={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `• ${point}`
                                }}
                            />
                        }
                    />
                </ListItem>
            ))}
            </List>
          )}
          <div className="flex flex-row justify-between w-full">
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                mt: 3,
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Register Modal */}
      <Modal
        open={registerOpen}
        onClose={handleCloseRegister}
        aria-labelledby="event-register-title"
        aria-describedby="event-register"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
            bgcolor: "#1f2937",
            border: "2px solid #000",
            boxShadow: "0 0 15px 5px rgba(255, 255, 255, 0.8)",
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography
            id="event-register-title"
            variant="h6"
            component="h2"
            className="text-white"
          >
            Register for event
          </Typography>
          <TextField
            label="Number of Participants"
            type="number"
            value={participants}
            onChange={handleParticipantsChange}
            inputProps={{ min: 1 }}
            fullWidth
            sx={{
              mt: 3,
              "& label": {
                color: "white",
              },
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInputBase-input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputProps={{
              style: { color: "white" },
            }}
          />
          {errorMsg && (
            <Typography className="text-red-500 mt-2">{errorMsg}</Typography>
          )}
          {paymentError && (
            <Typography className="text-red-500 mt-2">
              {paymentError}
            </Typography>
          )}
          {paymentSuccess && (
            <Typography className="text-green-500 mt-2">
              {paymentSuccess}
            </Typography>
          )}
          <Typography className="text-white py-2 lg:px-16 px-6">
            Amount Breakdown:
          </Typography>
          <div className="w-full flex flex-row justify-between lg:px-16 px-6">
            <div>
              <Typography className="text-slate-300 mt-1">
                Base Amount:
              </Typography>
              <Typography className="text-slate-300 mt-1">
                Razorpay Fee (2%):
              </Typography>
              <Typography className="text-white mt-1 font-bold pt-1">
                Total Amount:
              </Typography>
            </div>
            <div>
              <Typography className="text-slate-300 mt-1">
                {calculateBaseAmount()} Rs
              </Typography>
              <Typography className="text-slate-300 mt-1">
                {calculateRazorpayFee().toFixed(2)} Rs
              </Typography>
              <Typography className="text-white pt-1 font-bold">
                {calculateTotal().toFixed(2)} Rs
              </Typography>
            </div>
          </div>
          <div className="flex flex-row justify-between w-full mt-8">
            <Button
              onClick={handleCloseRegister}
              variant="outlined"
              sx={{
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
              }}
            >
              Close
            </Button>
            <Button
              onClick={handlePayment}
              variant="outlined"
              disabled={!!errorMsg}
              sx={{
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
                "&.Mui-disabled": {
                  backgroundColor: "rgba(111, 119, 253, 0.5)",
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              Register
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Events;
