import React, { useState } from "react";
import { useGetMeQuery, useLogoutMutation } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import {
  LogOut,
  LogIn,
  Home,
  Users,
  Building,
  Menu,
  X,
  PartyPopper,
} from "lucide-react";

const Header = () => {
  const { data, loading, error } = useGetMeQuery();
  const navigate = useNavigate();
  const [logoutMutation, { data: lData, loading: lLoading, error: lError }] =
    useLogoutMutation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logoutMutation();
      navigate("/auth");
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  const navButtons = [
    { label: "Ambassador", path: "/", icon: Users },
    { label: "Milans", path: "/milans", icon: PartyPopper },
    { label: "Accommodation", path: "/accommodation", icon: Building },
  ];

  const handleNavigation = (path: any) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-gray-900 bg-opacity-50 fixed top-0 left-0 right-0 z-30 backdrop-blur-sm">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src="https://saarang2024.s3.ap-south-1.amazonaws.com/logo.png"
              alt="Logo"
              className="w-16 h-16 lg:w-20 lg:h-20"
            />
            <div className="ml-4 hidden md:block">
              <h1 className="text-2xl lg:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                Saarang'25
              </h1>
            </div>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white focus:outline-none"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          <nav className="hidden md:flex space-x-4">
            {navButtons.map((button) => (
              <button
                key={button.label}
                onClick={() => handleNavigation(button.path)}
                className="flex items-center space-x-2 text-white hover:bg-white hover:bg-opacity-20 px-4 py-3 rounded-lg transition-all duration-300 ease-in-out text-2xl font-medium"
              >
                <button.icon size={24} />
                <span>{button.label}</span>
              </button>
            ))}
          </nav>

          <div className="hidden md:block">
            {data?.getMe ? (
              <button
                className="flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-6 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
                onClick={handleLogout}
              >
                <LogOut size={24} />
                <span>Logout</span>
              </button>
            ) : (
              <button
                className="flex items-center space-x-2 bg-emerald-500 hover:bg-emerald-600 text-white font-medium py-3 px-6 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
                onClick={() => handleNavigation("/auth")}
              >
                <LogIn size={24} />
                <span>Login</span>
              </button>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-4">
            <nav className="flex flex-col space-y-2">
              {navButtons.map((button) => (
                <button
                  key={button.label}
                  onClick={() => handleNavigation(button.path)}
                  className="flex items-center space-x-2 text-white hover:bg-white hover:bg-opacity-20 px-4 py-3 rounded-lg transition-all duration-300 ease-in-out text-lg font-medium"
                >
                  <button.icon size={24} />
                  <span>{button.label}</span>
                </button>
              ))}
              {data?.getMe ? (
                <button
                  className="flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-6 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
                  onClick={handleLogout}
                >
                  <LogOut size={24} />
                  <span>Logout</span>
                </button>
              ) : (
                <button
                  className="flex items-center space-x-2 bg-emerald-500 hover:bg-emerald-600 text-white font-medium py-3 px-6 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
                  onClick={() => handleNavigation("/auth")}
                >
                  <LogIn size={24} />
                  <span>Login</span>
                </button>
              )}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
